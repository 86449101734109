import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MenuItem, MessageService } from "primeng/api";
import { Observable, Subject, of } from "rxjs";
import { catchError, finalize, map, mapTo, takeUntil, tap } from "rxjs/operators";
import { Step } from "src/app/core/models/step";
import { AiLoggingService } from 'src/app/core/services/ai-logging.service';
import { SpinnerService } from "src/app/core/services/spinner.service";
import { Country } from "../../models/country";
import { MicrosoftService } from "../../models/microsoft-service";
import { NatureOfCrimesDTO } from "../../models/nature-of-crimes-dto.model";
import { NatureOfCrimes } from '../../models/nature-of-crimes-model';
import { NewRequestInternational } from '../../models/new-request-international';
import { SubmissionAttachment } from "../../models/submission-attachment.model";
import { FileUploadService } from "../../services/file-upload.service";
import { Store } from "../../services/store.service";
import { SubmissionService } from '../../services/submission.service';
import { DsaItemsComponent } from "../dsa-items/dsa-items.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { LegalNotificationComponent } from "../legal-notification/legal-notification.component";
import { NatureOfCrimeComponent } from "../nature-of-crime/nature-of-crime.component";
import { RequestAgentInfoComponent } from "../request-agent-info/request-agent-info.component";
import { TypeOfServiceComponent } from "../type-of-service/type-of-service.component";
import { DsaSelections } from '../../models/dsa-form-selections';
import { DsaContentTypes } from "../../models/dsa-content-types";

export enum StepId {
  TypeOfRequest = "typeOfRequest",
  NatureOfCrimes = "natureOfCrimes",
  RequestingAgentInformation = "requestingAgentInformation",
  TypeOfService = "typeOfService",
  Notification = "notification",
  FileUpload = "fileUpload",
  DsaItems = "dsaItems",
  ReviewAndSubmit = "reviewAndSubmit"
}

@Component({
  selector: "new-request-international",
  templateUrl: "./new-request-international.component.html",
  styleUrls: ["./new-request-international.component.scss"]
})
export class NewRequestInternationalComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() country: Country;
  @Input() showInternationalWarning: boolean;
  private ngUnsub: Subject<void> = new Subject<void>();
  yesno: { label: string; value: boolean; icon: string }[] = [
    { label: "Yes", value: true, icon: "pi pi-check" },
    { label: "No", value: false, icon: "pi pi-times" }
  ];

  // Stepper
  activeIndex: Store<number>;
  items: MenuItem[];
  steps: Step[] = [];

  // Variables
  internationalHeader: string;
  internationalWarningText: string;
  submitting: boolean = false;
  submissionHistoryLocator: string;
  fileNames: string[];
  reviewAndSubmitStep: number;
  public displayDsa: boolean = false;

  // Footer Buttons
  showCancelWithFileDeleteDialog: boolean;
  showCancelSimpleDialog: boolean;
  isFaulted: boolean = false;

  get isSubmitDisabled(): boolean {
    return this.form.invalid || this.submitting || this.isFaulted;
  }
  constructor(
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private router: Router,
    private spinnerService: SpinnerService,
    private submissionService: SubmissionService,
    private logger: AiLoggingService,
    private zone: NgZone
  ) { }
  ngOnInit() {
    this.setInternationalWarningText();
    this.buildStepsAndLabels();
    this.addBaseFormControls();
    this.activeIndex = new Store<number>(0);
    // Various buttons react different depending on the final step. We define that here for easy updating in the future.
    this.reviewAndSubmitStep = this.steps.length - 1;
  }

  ngOnDestroy() {
    if (this.form) {
      this.form.removeControl("international");
    }
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  //#region Accessors
  // provided as forms to sub-components
  get childExploitation() {
    return this.international.get("cpConcern").value === true ? "Yes" : "No";
  }

  get international(): FormGroup {
    return this.form.get("international") as FormGroup;
  }
  get requestingAgentInformationForm(): FormGroup {
    return this.international.get("requestingAgentInformationForm") as FormGroup;
  }
  get typeOfServiceForm(): FormGroup {
    return this.international.get("typeOfServiceForm") as FormGroup;
  }
  get notificationForm(): FormGroup {
    return this.international.get("notificationForm") as FormGroup;
  }
  get fileUploadForm(): FormGroup {
    return this.international.get("fileUploadForm") as FormGroup;
  }
  get natureOfCrimeForm(): FormGroup {
    return this.international.get("natureOfCrimeForm") as FormGroup;
  }
  get dsaItemsForm(): FormGroup {
    return this.international.get("dsaItemsForm") as FormGroup;
  }
  get isDifferentRecipientControl(): FormControl {
    return this.international.get("hasDifferentRecipient") as FormControl;
  }
  get isPreservationRequestControl(): FormControl {
    return this.international.get("isPreservationRequest") as FormControl;
  }
  get isPreservationAttestionControl(): FormControl {
    return this.international.get("isPreservationAttestion") as FormControl;
  }
  get isCPConcernControl(): FormControl {
    return this.international.get("cpConcern") as FormControl;
  }
  // for the review step
  get notificationPermitted() {
    return this.notificationForm.get("notification").value === true ? "Yes" : "No";
  }
  get isPreservation() {
    return this.isPreservationRequestControl.value
      ? "Yes"
      : "No";
  }
  get differentRecipientReview() {
    if (
      this.international.get("hasDifferentRecipient") &&
      this.international.get("hasDifferentRecipient").value
    ) {
      return this.international.get("deliveryEmail").value;
    } else {
      return "N/A";
    }
  }
  get agencyName() {
    return this.requestingAgentInformationForm.get("agencyName").value;
  }
  get agentFirstName() {
    return this.requestingAgentInformationForm.get("agentFirstName").value;
  }
  get agentLastName() {
    return this.requestingAgentInformationForm.get("agentLastName").value;
  }
  get agentEmail() {
    return this.requestingAgentInformationForm.get("agentEmail").value;
  }
  get sendToEmail() {
    return this.requestingAgentInformationForm.get("sendToEmail").value;
  }
  get agentPhoneNumber() {
    return this.requestingAgentInformationForm.get("agentPhoneNumber").value;
  }
  get leReferenceNumber() {
    return this.international.get("leReferenceNumber").value;
  }

  /**
  * This function retrieves the values of the NatureOfCrimes form.
  * 
  * It first gets the list of the field names of the NatureOfCrimes class.
  * It then iterates through these names and uses them to retrieve the corresponding values from the form controls.
  * 
  * The `reduce` function is used to build up an object of the NatureOfCrimesDTO type, where each property 
  * corresponds to a field name of the NatureOfCrimes class, and the value is the value of the corresponding form control.
  * 
  * This object will be used to send data to the backend in the required format.
  * 
  * @returns NatureOfCrimesDTO object which contains selected nature of crimes.
  */
  getNatureOfCrimeValues(): NatureOfCrimesDTO {
    const natureOfCrimesProperties = NatureOfCrimes.getPropertyNames();
    const natureOfCrimesObject: NatureOfCrimesDTO = natureOfCrimesProperties.reduce(
      (acc: NatureOfCrimesDTO, value: string) => {
        const formControlName = NatureOfCrimes.modifyFormControlName(NatureOfCrimes[value]);
        acc[value] = this.natureOfCrimeForm.get(formControlName).value;
        return acc;
      },
      {} as NatureOfCrimesDTO
    );
    natureOfCrimesObject["DescriptionOther"] = this.natureOfCrimeForm.get("isOtherDescription").value;
    return natureOfCrimesObject;
  }

  get isUK() {
    return this.country.Name == 'United Kingdom'
  }
  get services() {
    const names: Array<string> = [];
    const defaultOptionsSelected = this.typeOfServiceForm.get("selectedServices")
      .value as MicrosoftService[];
    const manuallyEntered = this.typeOfServiceForm.get("manualServices").value as Array<
      string
    >;
    defaultOptionsSelected.forEach(option => names.push(option.Name));

    // we remove any duplicates, in case they manually entered Skype or something
    const deDuped = Array.from(new Set(names.concat(manuallyEntered)));
    // remove any empty values
    return deDuped.filter(val => val != (undefined || null || "" || ''));
  }

  getSelectedSubcategories(formGroup: FormGroup): string[] {
    const selectedSubcategories = [];
    
    const subcategoryKeys = DsaContentTypes.getPropertyNames()
      .map(name => DsaContentTypes.modifyFormControlName(name));
  
    subcategoryKeys.forEach(key => {
      const control = formGroup.get(key);
      if (control && control.value === true) {
        selectedSubcategories.push(key);
      }
    });
  
    return selectedSubcategories;
  }
  
  getSubcategoryDisplayNames(selectedKeys: string[]): string[] {
    const displayNames = selectedKeys.map(key => {
      const originalName = Object.keys(DsaContentTypes)
        .find(name => DsaContentTypes.modifyFormControlName(name) === key);
      return DsaContentTypes[originalName];
    });
  
    return displayNames;
  }

  get selectedDsaKeys(): string[] {
    return this.getSelectedSubcategories(this.dsaItemsForm.get("dsaSelections") as FormGroup);
  } 

  get dsaDisplayNames(): string[] {
    return this.getSubcategoryDisplayNames(this.selectedDsaKeys);
  }
  
  //#endregion

  get isDsaRequestControl() {
    return this.form.get('international.dsaItemsForm.isDsaRequest');
  }
  get isDsaRequest() {
    return this.isDsaRequestControl.value;
  }

  dsaFormIsValid = false;

  // errors
  get notSame() {
    return (
      this.international.get("acknowledgement").hasError("notSame") &&
      this.international.get("acknowledgement").dirty
    );
  }


  closeInternational() {
    this.showInternationalWarning = false;
    if (this.country.IsDSACountry) {
      this.displayDsa = true;
    }
  }


  onDsaResponse(answer: boolean) {
    this.displayDsa = false;
    this.isDsaRequestControl.setValue(answer);
  }

  public dsaAcknowledgementText = "I declare and attest that the above information is true and correct; that I am " +
    "authorised to submit the accompanying legal request and that my request meets " +
    "the requirements of the EU DSA."

  private addBaseFormControls() {
    this.form.addControl(
      "international",
      new FormGroup({
        requestingAgentInformationForm: new FormGroup({}),
        typeOfServiceForm: new FormGroup({}),
        notificationForm: new FormGroup({}),
        fileUploadForm: new FormGroup({}),
        dsaItemsForm: new FormGroup({
          isDsaRequest: new FormControl(false)
        }),
        natureOfCrimeForm: new FormGroup({}, Validators.required),
        countryOrigin: new FormControl(this.country.Id),
        isPreservationRequest: new FormControl(null, Validators.required),
        isGradeTwo: new FormControl(false),
        hasDifferentRecipient: new FormControl(false),
        deliveryEmail: new FormControl(null),
        leReferenceNumber: new FormControl(null, Validators.required),
        isPreservationAttestion: new FormControl(null, Validators.required),
        cpConcern: new FormControl(null)
      })
    );

    // If it's preservation, we don't offer altenrative delivery. Wipe any prior value
    this.isPreservationRequestControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsub),
        tap(isPreservation => {
          if (isPreservation) {
            this.isDifferentRecipientControl.setValue(null);
            this.isDifferentRecipientControl.clearValidators();
            this.isDifferentRecipientControl.markAsPristine();
            this.isDifferentRecipientControl.updateValueAndValidity();
          }
          else {
            this.isPreservationAttestionControl.clearValidators();
            this.isPreservationAttestionControl.markAsPristine();
            this.isPreservationAttestionControl.updateValueAndValidity();
            this.isDifferentRecipientControl.setValidators(Validators.required);
            this.international.updateValueAndValidity();
          }
        })
      )
      .subscribe()

    // If they decide they don't want a different recipient, wipe the value that may be in the delivery control
    this.isDifferentRecipientControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsub),
        tap(isDifferent => {
          if (isDifferent) {
            this.international.get('deliveryEmail').setValidators([Validators.email, Validators.required]);
          } else {
            this.international.get('deliveryEmail').setValue(null);
            this.international.get('deliveryEmail').clearValidators();
            this.international.get('deliveryEmail').markAsPristine();
            this.international.get('deliveryEmail').updateValueAndValidity();
          }
        })
      )
      .subscribe()

  }

  addControlValuesToForm(
    formGroup: FormGroup | FormArray,
    formToSend: FormData
  ): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as
        | FormControl
        | FormGroup
        | FormArray;
      // handle our files. Brittle as this assumes only our files use formarray, but that's the case today
      if (control instanceof FormArray) {
        for (const file of control.controls) {
          const fileToUpload = file.value;
          formToSend.append(fileToUpload.name, fileToUpload);
        }
      } else if (control instanceof FormControl) {
        formToSend.append(key, control.value);
      } else if (control instanceof FormGroup) {
        this.addControlValuesToForm(control, formToSend);
      } else {
        console.log("ignoring control");
      }
    });
  }

  // Used to download the files a user uploads during the submission process
  doGetFile(fileName) {
    this.fileUploadService
      .getFile(this.submissionHistoryLocator, fileName)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(value => {
          const file: string = value;
          window.open(file, "_blank");
        }),
        catchError(error => {
          this.sendUserMessagePopup(
            "error",
            "Content Download Error",
            "Your attempt to download this file failed. Please try again."
          );

          throw error;
        })
      )
      .subscribe();
  }

  // This method cancels the submission request the user is working on
  deleteUploadedFilesAndCancel() {
    this.showCancelWithFileDeleteDialog = false;
    this.spinnerService.startSpinner();
    this.fileUploadService
      .removeSubmissionRequest(this.submissionHistoryLocator)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(
          () => {
            this.sendUserMessagePopup(
              "success",
              "Submission Canceled",
              "Successfully canceled your submission request."
            );

            this.spinnerService.stopSpinner();
            // navigate to success page
            this.router.navigate(["/dashboard"]);
          },
          (error: any) => {
            this.sendUserMessagePopup(
              "error",
              "Cancel Error",
              "Part of the clean-up failed."
            );

            this.spinnerService.stopSpinner();
            this.router.navigate(["/dashboard"]);
          }
        )
      )
      .subscribe();
  }

  prepareForm(): NewRequestInternational {
    return {
      CountryOrigin: this.country,
      DeliveryEmail: this.international.get("deliveryEmail")
        ? this.international.get("deliveryEmail").value
        : "",
      HasDifferentRecipient: this.international.get("hasDifferentRecipient")?.value || false,
      IsPreservation: this.international.get("isPreservationRequest").value,
      IsGradeTwo: this.international.get("isGradeTwo").value,
      SubmissionHistoryLocator: this.submissionHistoryLocator,
      AgencyName: this.requestingAgentInformationForm.get('agencyName').value,
      AgentFirstName: this.requestingAgentInformationForm.get('agentFirstName').value,
      AgentLastName: this.requestingAgentInformationForm.get('agentLastName').value,
      AgentEmail: this.requestingAgentInformationForm.get('agentEmail').value,
      AgentPhone: this.requestingAgentInformationForm.get('agentPhoneNumber').value,
      LEReferenceNumber: this.international.get('leReferenceNumber').value,
      NotifyTarget: this.notificationForm.get('notification').value,
      Services: this.services,
      NatureOfCrimes: this.getNatureOfCrimeValues(),
      IsChildExploitation: this.international.get("cpConcern").value,
      DsaSelections: this.getDsaSelections()
    };
  }

  prepareFormData(): FormData {
    const formToSend = new FormData();
    this.addControlValuesToForm(this.international, formToSend);
    return formToSend;
  }
  
  getDsaSelections(): DsaSelections {
    const isDsaRequest = this.dsaItemsForm.get('isDsaRequest')?.value || false;
  
    if (!isDsaRequest) {
      return {
        IsDsaRequest: false,
        IsLegalOrderCompliant: false,
        IsMicrosoftRequiredNoticeAcknowledgement: false,
        IsValidNDOInPlace: false,
        IsTemporaryNonDisclosure: false,
        NonDisclosureExpirationDate: null,
        DsaContentTypes: []
      };
    }
  
    const isLegalOrderCompliant = this.dsaItemsForm.get('isLegalOrderCompliant')?.value || false;
    const isMicrosoftRequiredNoticeAcknowledgement = this.dsaItemsForm.get('isMicrosoftRequiredNoticeAcknowledgement')?.value || false;
    const isValidNDOInPlace = this.dsaItemsForm.get('isValidNDOInPlace')?.value || false;
    const isTemporaryNonDisclosure = this.dsaItemsForm.get('isTemporaryNonDisclosure')?.value || false;
    const nonDisclosureExpirationDate = this.dsaItemsForm.get('nonDisclosureExpirationDate')?.value || null;
  
    const dsaSelectionsFormGroup = this.dsaItemsForm.get('dsaSelections') as FormGroup;
    const selectedSubcategories = dsaSelectionsFormGroup ? this.getSelectedSubcategories(dsaSelectionsFormGroup) : [];
  
    return {
      IsDsaRequest: isDsaRequest,
      IsLegalOrderCompliant: isLegalOrderCompliant,
      IsMicrosoftRequiredNoticeAcknowledgement: isMicrosoftRequiredNoticeAcknowledgement,
      IsValidNDOInPlace: isValidNDOInPlace,
      IsTemporaryNonDisclosure: isTemporaryNonDisclosure,
      NonDisclosureExpirationDate: nonDisclosureExpirationDate,
      DsaContentTypes: selectedSubcategories
    };
  }
  
  

  previous() {
    this.activeIndex.setState(this.activeIndex.state - 1);
  }

  next() {
    const currentStepId = this.getCurrentStepId();
    if (!currentStepId || !this.validationStrategies[currentStepId]) {
      console.error("Invalid step identifier or no validation strategy found for:", currentStepId);
      return;
    }
  
    this.validationStrategies[currentStepId]()
      .pipe(
        takeUntil(this.ngUnsub),
        tap(isValid => {
          if (isValid) {
            this.activeIndex.setState(this.activeIndex.state + 1);
          }
        }))
      .subscribe();
  }
  


  // If you add new Steps, this is the place to provide any validation logic for those Steps. 
  validationStrategies: { [key: string]: () => Observable<boolean> } = {
    [StepId.TypeOfRequest]: () => this.validateTypeOfRequest(),
    [StepId.NatureOfCrimes]: () => this.validateNatureOfCrimes(),
    [StepId.RequestingAgentInformation]: () => this.validateRequestingAgentInformation(),
    [StepId.TypeOfService]: () => this.validateTypeOfService(),
    [StepId.Notification]: () => this.validateNotification(),
    [StepId.FileUpload]: () => this.validateFileUpload(),
    [StepId.DsaItems]: () => of(this.dsaFormIsValid)
  };
  

  getCurrentStepId(): string {
    const currentIndex = this.activeIndex.state;
    const currentStep = this.steps[currentIndex];

    return currentStep ? currentStep.id : null;
  }

  validateTypeOfRequest(): Observable<boolean> {
    let isValid = true;
  
    if (this.isCPConcernControl.value == null) {
      this.sendUserMessagePopup(
        "error",
        "Child Exploitation",
        "Please indicate if this is a Child Exploitation request."
      );
      isValid = false;
    }
  
    if (this.isPreservationRequestControl.value) {
      if (!this.isPreservationAttestionControl.value || this.isPreservationAttestionControl.value == null) {
        this.sendUserMessagePopup(
          "error",
          "Preservation Request - Attestation",
          "Please provide the preservation request attestation."
        );
        isValid = false;
      }
    }
  
    if (this.isPreservationRequestControl.invalid
      || this.isDifferentRecipientControl.invalid
      || this.international.get('deliveryEmail').invalid
      || this.international.get('leReferenceNumber').invalid) {
      if (this.isPreservationRequestControl.invalid) {
        this.sendUserMessagePopup(
          "error",
          "Preservation Request",
          "Please indicate if this is a preservation request."
        );
        isValid = false;
      }
      if (this.isDifferentRecipientControl.invalid) {
        this.sendUserMessagePopup(
          "error",
          "Different Recipient",
          "Please indicate if you would like to designate a different recipient."
        );
        isValid = false;
      }
      if (this.international.get('deliveryEmail').invalid) {
        this.sendUserMessagePopup(
          "error",
          "OnBehalfOf",
          "Please enter a valid email."
        );
        isValid = false;
      }
      if (this.international.get('leReferenceNumber').invalid) {
        this.sendUserMessagePopup(
          "error",
          "LE Reference Number",
          "Value cannot be left blank."
        );
        isValid = false;
      }
    }
    return of(isValid);
  }
  

  validateNatureOfCrimes(): Observable<boolean> {
    if (!this.natureOfCrimeForm.valid) {
      this.sendUserMessagePopup(
        "error",
        "Incomplete Nature of Crime(s) Step",
        "Please validate your Nature of crime(s) selections."
      );
      return of(false);
    }
    if (this.natureOfCrimeForm.get('isOtherDescription').invalid) {
      this.sendUserMessagePopup(
        "error",
        "Nature Of Crime Other Description is missing.",
        "Description is required when Other is selected."
      );
      return of(false);
    }
    return of(true);
  }

  validateRequestingAgentInformation(): Observable<boolean> {
    if (!this.requestingAgentInformationForm.valid) {
      this.sendUserMessagePopup(
        "error",
        "Incomplete Requesting Agent Step",
        "Please ensure all the fields are filled out with valid data."
      );
      return of(false);
    }
    if (this.requestingAgentInformationForm.hasError("noMatch")) {
      this.sendUserMessagePopup(
        "error",
        "Confirmed Email does not match",
        "Please ensure the email confirmation fields match."
      );
      return of(false);
    }
    return of(true);
  }

  validateTypeOfService(): Observable<boolean> {
    if (!this.typeOfServiceForm.valid) {
      this.sendUserMessagePopup(
        "error",
        "Incomplete Type of Service",
        "Please select or enter at least one service."
      );
      return of(false);
    }
    return of(true);
  }

  validateNotification(): Observable<boolean> {
    if (!this.notificationForm.valid) {
      this.sendUserMessagePopup(
        "error",
        "Target Notification Permitted",
        "Please select the permission for this request."
      );
      return of(false);
    }
    return of(true);
  }

  validateFileUpload(): Observable<boolean> {
    if (this.fileUploadForm.get("files").hasError("invalidMinCount")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "You must upload a file before you continue.  Please try again."
      );
      return of(false);
    }
    if (this.fileUploadForm.get("files").hasError("invalidSize")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file did not meet the size requirements.  Please try again."
      );
      return of(false);
    }
    if (this.fileUploadForm.get("files").hasError("invalidExt")) {
      this.sendUserMessagePopup(
        "error",
        "Attachment Upload Error",
        "Your file has an invalid file extension.  Please try again."
      );
      return of(false);
    }
    return this.uploadAttachmentsAndSaveRequestState();
  }

  // Utility method for creating pop messages that the user will see
  sendUserMessagePopup(type: string, title: string, message: string) {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: "toast-popup"
    });
  }

  setInternationalWarningText() {
    this.internationalHeader = this.country.InternationalWarning.Header;
    this.internationalWarningText = this.country.InternationalWarning.WarningText;
  }

  private buildStepsAndLabels() {
    const addBaseSteps = (existingSteps: Step[]): Step[] => {
      const baseSteps = [
        { component: null, label: "Type of Request", id: StepId.TypeOfRequest }, // Angelo: this needs to be its own component. Tech Debt.
        { component: TypeOfServiceComponent, label: "Type of Service", data: this.form, id: StepId.TypeOfService },
        { component: NatureOfCrimeComponent, label: "Nature of Crimes Details", data: this.form, id: StepId.NatureOfCrimes },
        { component: RequestAgentInfoComponent, label: "Requesting Agent Information", data: this.form, id: StepId.RequestingAgentInformation },
        { component: LegalNotificationComponent, label: "Notification", data: this.form, id: StepId.Notification },
        { component: FileUploadComponent, label: "File Upload", data: this.form, id: StepId.FileUpload },
        { component: null, label: "Acknowledge & Submit", id: StepId.ReviewAndSubmit } // Angelo: This needs to be its own component. Tech Debt.
      ];
      return [...existingSteps, ...baseSteps];
    };

    const insertDSAStepIfRequired = (steps: Step[], countryName: string): Step[] => {
      if (this.country.IsDSACountry) {
        const updatedSteps = [...steps];
        updatedSteps.splice(1, 0, { component: DsaItemsComponent, label: "Digital Services Act (DSA)", id: "dsaItems" });
        return updatedSteps;
      }
      return steps;
    };

    this.steps = addBaseSteps(this.steps);
    this.steps = insertDSAStepIfRequired(this.steps, this.country.Name);

    this.setStepperLabels();
  }

  private setStepperLabels() {
    this.items = this.steps.map(step => ({ label: step.label }));
  }

  submit() {
    this.logger.logInformation("Submitting new request.");
    const formToSend = this.prepareForm();
    this.submitting = true;
    this.spinnerService.startSpinner();

    this.submissionService
      .postInternationalSubmission(formToSend)
      .pipe(
        takeUntil(this.ngUnsub),
        tap(() => {
          this.logger.logInformation("Successfully submitted new request.");
          this.sendUserMessagePopup(
            "success",
            "Submission Confirmation",
            "Thank you for your submission.  Your request will be reviewed."
          );

          this.submitting = false;
          this.spinnerService.stopSpinner();
          // navigate to success page
          this.router.navigate(["/dashboard"], {
            queryParamsHandling: "preserve",
          });
        }),
        catchError((error) => {
          this.logger.logInformation("Failed to submit new request.");
          this.sendUserMessagePopup(
            "error",
            "Submission Error",
            "Submission failed.  Please try again."
          );
          this.submitting = false;
          this.isFaulted = true;
          this.spinnerService.stopSpinner();
          // throw so the Global handler can log this
          return of(error);
        })
      )
      .subscribe();
  }

  // This method is used to show/hide the cancel dialog box
  toggleCancelWithFileDeleteDialog() {
    this.showCancelWithFileDeleteDialog = !this.showCancelWithFileDeleteDialog;
  }

  // This is used to toggle the simple cancel function, which simply routes the user back to the dashboard.
  // This is only used in steps 1-3 before they upload any files.
  toggleSimpleCancelDialog() {
    this.showCancelSimpleDialog = !this.showCancelSimpleDialog;
  }

  uploadAttachmentsAndSaveRequestState(): Observable<boolean> {
    const formModel: FormData = this.prepareFormData();
    this.spinnerService.startSpinner();
    return this.fileUploadService
      .postAttachment(formModel)
      .pipe(
        tap(resp => {
          let sub: SubmissionAttachment = resp.body;
          this.submissionHistoryLocator = sub.SubmissionHistoryLocator;
          this.fileNames = sub.FileNames;
          this.sendUserMessagePopup(
            "success",
            "Attachments Upload Confirmation",
            "Successfully uploaded your attachment, please continue to finish the submission."
          );
        }),
        mapTo(true),
        finalize(() => this.zone.run(() => this.spinnerService.stopSpinner())),
        catchError(_ => {
          this.sendUserMessagePopup(
            "error",
            "File Upload Error",
            "Submission failed. Please try again."
          );
          return of(false)
        }))
  }
}
