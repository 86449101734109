import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MicrosoftService } from "../../models/microsoft-service";
import { AtLeastOneValue } from '../validators/at-least-one.validator';

@Component({
  selector: "type-of-service",
  templateUrl: "./type-of-service.component.html",
  styleUrls: ["./type-of-service.component.scss"]
})
export class TypeOfServiceComponent implements OnInit {
  @Input() form: FormGroup;
  services: MicrosoftService[];

  constructor() { }

  ngOnInit() {
    this.form.addControl("selectedServices", new FormControl());
    this.form.addControl("manualServices", new FormControl());
    // They either need to select a service or enter a manual one
    this.form.setValidators(AtLeastOneValue());
    this.form.updateValueAndValidity();
    this.setServices();
    this.setManualRequiredForOtherSelection();
  }

  private setManualRequiredForOtherSelection() {
    this.form.get('selectedServices').valueChanges.subscribe(selected => {
      if (selected.map(s => s.Id).includes(27)) {
        this.form.get('manualServices').setValidators(Validators.required);
      } else {
        this.form.get('manualServices').clearValidators();
      }
      this.form.get('manualServices').updateValueAndValidity();
    });
  }

  private setServices() {
    this.services = [
      { Id: 1, Name: "Skype" },
      { Id: 2, Name: "Xbox" },
      { Id: 3, Name: "Azure" },
      { Id: 4, Name: "Bing" },
      { Id: 5, Name: "Bookings" },
      { Id: 6, Name: "Customer Service" },
      { Id: 7, Name: "Designer" },
      { Id: 8, Name: "Edge Browser" },
      { Id: 9, Name: "E-mail Consumer" },
      { Id: 10, Name: "E-mail Enterprise" },
      { Id: 11, Name: "Eventify" },
      { Id: 12, Name: "Forms" },
      { Id: 13, Name: "GitHub" },
      { Id: 14, Name: "Lists" },
      { Id: 15, Name: "Loop" },
      { Id: 16, Name: "MakeCode" },
      { Id: 17, Name: "Microsoft Answers Platform" },
      { Id: 18, Name: "Microsoft Copilot Studio" },
      { Id: 19, Name: "Microsoft Learn" },
      { Id: 20, Name: "MS Ads" },
      { Id: 21, Name: "MS Store" },
      { Id: 22, Name: "OneDrive for Business (ODB)" },
      { Id: 23, Name: "OneDrive Consumer (ODC)" },
      { Id: 24, Name: "Other" },
      { Id: 25, Name: "Retail Media" },
      { Id: 26, Name: "SharePoint Online" },
      { Id: 27, Name: "Teams" },
      { Id: 28, Name: "Two Hat" },
      { Id: 29, Name: "Visual Studio" },
      { Id: 30, Name: "Viva" },
      { Id: 31, Name: "Windows 365 Consumer" },
      { Id: 32, Name: "Yammer" }
    ];
  }
}
