<div class="acknowledgement-container" [formGroup]="form">
    <div class="acknowledgement-content">
      <p><b>{{ declarationText }}</b></p>
      <p>Please type "{{acknowledgementText}}" in the box below to acknowledge.&nbsp;<span class="required-asterisk">*</span></p>
      <span class="input-container">
        <input 
          type="text" 
          formControlName="{{acknowledgementFormControlName}}"
          pInputText 
        />
      </span>
      <ng-container *ngIf="isInvalid">
        <div class="error-message">
          {{ errorMessage }}
        </div>
      </ng-container>
    </div>
  </div>