const baseUri = 'https://apim-lens-dev.azure-api.net/leportalapi/api';
const authClientId = '92181410-d890-41af-be63-4755e654d9da';

export const environment = {
  base: baseUri,
  authClientId: authClientId,
  production: false,
  hmr: false,
  appInsightsConfig: {
    key: 'b5a069e8-5023-4a98-8cb9-2eff98eeb093'
  },
  authUrl: baseUri + '/login/validatetoken',
  countryListUrl: baseUri + '/countrylist',
  feedbackUrl: baseUri + '/feedback',
  internationalReviewUrl: baseUri + '/international/review',
  registrationUrl: baseUri + '/register',
  submissionUrl: baseUri + '/submission',
  submissionAttachmentUploadUrl: baseUri + '/submission/uploadFile',
  submissionCreateGCCUrl: baseUri + '/submission/sendToCRM',
  submissionGetAttachmentUrl: baseUri + '/submission/getfile/',
  mySubmissionsUrl: baseUri + '/submission/my',
  contentReactivationUrl: baseUri + '/contentreactivation',
  deliveryUrl: baseUri + '/storage',
  exchangeRequestsUrl: baseUri + '/exchangeRequests',
  messageCenterUrl: baseUri + '/serviceMessages',
  redirectUrl: "https://leportaldev.microsoft.com/auth",
  featureFlagUrl: baseUri + '/configsetting/GetFeatureFlag?key=',
  configSettingUrl: null,
  videoAssets: {
    registrationVideoUrl: 'https://leportalpublicstore.z22.web.core.windows.net/assets/videos/1a8e7bb15fd1404a812e20d6a93323ca/LE_Portal_Video_1_MIXEDAudio_2Mbps.mp4',
    dataDownloadVideoUrl: 'https://leportalpublicstore.z22.web.core.windows.net/assets/videos/94f1f4f7cba94297982afc75518cdc42/LE_Portal_Video_2_MIXEDAudio_2Mbps.mp4'
  },
  tenant: '9188040d-6c67-4c5b-b112-36a304b66dad',
  scopes: [
    "openid",
    "profile",
    "email",
    `api://${authClientId}/access_as_user`
  ]
};

