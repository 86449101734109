import { FormGroup, ValidatorFn } from '@angular/forms';

export function dsaComplianceValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const isDsaRequest = formGroup.get('isDsaRequest')?.value;
    const isLegalOrderCompliant = formGroup.get('isLegalOrderCompliant')?.value;

    if (isDsaRequest && !isLegalOrderCompliant) {
      return { dsaComplianceError: true };
    }
    return null;
  };
}
